<template>
  <div class="d-flex dcf-toolbar align-center py-2">
    <v-spacer></v-spacer>
    <v-btn text small @click="feedbackSend">
      <v-icon class="mr-2">mdi-comment-quote-outline</v-icon>
    </v-btn>

    <v-btn text small @click="printWindow">
      <v-icon class="mr-2">mdi-printer</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: { mailtoAddr: { required: true } },

  data() {
    return {
      publicPath: process.env.BASE_URL
    }
  },
  methods: {
    feedbackSend() {
      location.href = this.mailtoAddr
    },
    printWindow() {
      window.print()
    }
  }
}
</script>

<style lang="scss">
.dcf-toolbar {
  border-left: 1px solid #014165;
  border-right: 1px solid #014165;
  border-bottom: 1px solid #014165;
  background-color: #d1d1d1;
  color: #333;

  .v-btn {
    text-transform: none;
    color: #333;
  }

  .custom-select {
    appearance: listbox;
    background: #fff;
    border: 1px solid #999;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    line-height: 28px;
    height: 28px;
    padding: 0 8px;
    border-radius: 4px;
  }

  .custom-title-input.v-input--dense {
    .v-input__slot {
      min-height: 28px !important;
      padding: 0 4px !important;
      background: #fff;
    }
    input {
      font-size: 12px;
      padding: 0 4px;
      text-align: left;
    }
  }

  .custom-pager .v-btn.v-btn--custom {
    min-width: 28px;
  }

  .animate-element {
    will-change: margin-top;
    height: 28px;
    transform: margin-top 1s;
  }

  .show-confirmation {
    margin-top: -28px;
  }
}
</style>
