<template>
  <div>
    <highcharts :options="chartOptions" :callback="addDescriptionButton"></highcharts>
    <v-dialog scrollable max-width="900px" v-model="openDialog">
      <v-card>
        <v-card-title>Steady State vs. Future Value</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px;width:900px">
          <div class="chart-description">
            <p>
              This index shows us how the relative level of future value and steady state implied by the market price
              for the particular company compare to the average levels implied by the market for all the stocks in the
              sector.
            </p>
            <p>
              The values are a (modified z-) score and not a percentage. Lower scores indicate that, judging solely by
              the relative levels of future value and steady state, the stock is cheap within the sector, and
              vice-versa.
            </p>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="openDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog scrollable max-width="900px" v-model="openPeersDialog">
      <v-card>
        <v-card-title>Peers List</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 500px;width:900px">
          <div class="peers">
            <PeersList :getChartData="getChartData" />
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="openPeersDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { excessReturnColors } from "@/components/ValuationCharts/ExcessReturnColor.js"
import { timeseries } from "@/utils/timeseries.js"
import PeersList from "@/components/ValuationCharts/PeersList"
import numeral from "numeral"
export default {
  props: ["getChartData"],
  components: {
    PeersList
  },
  methods: {
    addDescriptionButton(chart) {
      var that = this
      chart.renderer
        .button("Description", 0, 0)
        .attr({
          zIndex: 3
        })
        .on("click", function() {
          that.openDialog = true
        })
        .add()

      chart.renderer
        .button("Peers", chart.plotWidth - 30, 0)
        .attr({
          zIndex: 3
        })
        .on("click", function() {
          that.openPeersDialog = true
        })
        .add()
    }
  },
  data: function() {
    return {
      openDialog: false,
      openPeersDialog: false,
      chartOptions: {
        chart: {
          type: "area",
          zoomType: "x"
        },
        colors: excessReturnColors,
        credits: { enabled: false },
        title: {
          text: "Sector Valuation Index"
        },
        tooltip: {
          xDateFormat: "%Y-%m-%d",
          formatter: function() {
            // The first returned item is the header, subsequent items are the
            // points
            var dt = new Date(this.x).toDateString()
            return ["<b>" + dt + "</b>"].concat(
              this.points
                ? this.points.map(function(point) {
                    return point.series.name + ": " + numeral(point.y).format("0a")
                  })
                : []
            )
          },
          split: true
        },
        xAxis: {
          type: "datetime"
        },
        yAxis: {
          title: {
            text: ""
          },
          labels: {
            formatter: function() {
              return this.value
            }
          }
        },
        plotOptions: {
          area: {
            stacking: "normal",
            lineWidth: 0
          },
          series: {
            marker: {
              enabled: false
            }
          }
        },
        series: [
          {
            type: "area",
            data: [],
            name: ""
          }
        ]
      }
    }
  },
  async mounted() {
    const cdata = await this.getChartData("aggregate_valuation_index")
    this.chartOptions.series[0].data = timeseries(cdata.index).map(([k, d]) => [Date.parse(k), d])
    this.chartOptions.series[0].name = this.chartOptions.title.text
  }
}
</script>
