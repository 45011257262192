<template>
  <div>
    <v-data-table :headers="headers" :items="dataRows" :items-per-page="1000" class="elevation-1"></v-data-table>
  </div>
</template>

<script>
export default {
  props: ["getChartData"],
  methods: {
    addDescriptionButton(chart) {
      var that = this
      chart.renderer
        .button("Description", 0, 0)
        .attr({
          zIndex: 3
        })
        .on("click", function() {
          that.openDialog = true
        })
        .add()
    }
  },
  data() {
    return {
      cdata: null,
      headers: [
        {
          text: "Company Name",
          align: "start",
          sortable: true,
          value: "name"
        },
        { text: "Symbol", value: "ticker" }
      ],
      dataRows: []
    }
  },
  async mounted() {
    const cdata = await this.getChartData("peers")
    if (cdata.peers) {
      this.dataRows = cdata.peers
        .map(function(item) {
          return {
            name: item[0],
            ticker: item[1]
          }
        })
        .sort(function(a, b) {
          let name1 = a.name.toUpperCase()
          let name2 = b.name.toUpperCase()

          let comparison = 0

          if (name1 > name2) {
            comparison = 1
          } else if (name1 < name2) {
            comparison = -1
          }
          return comparison
        })
    }
  }
}
</script>
