<template>
  <div>
    <highcharts :options="chartOptions" :callback="addDescriptionButton"></highcharts>
    <v-dialog scrollable max-width="900px" v-model="openDialog">
      <v-card>
        <v-card-title>Invested Capital</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px;width:900px">
          <div class="chart-description">
            <p>
              The invested capital is calculated using the asset side of the balance sheet. As a default, it is
              calculated as
            </p>
          </div>
          <v-card-text class="formula">
            <img :src="`${publicPath}files/formulas/invested_capital_1.PNG`" />
          </v-card-text>
          <div class="chart-description">
            From this default invested capital calculation, users have the following options to amend the invested
            capital calculation as suited:
            <ul>
              <li>
                Capitalise R&D: there are two effects of capitalising the R&D rather than expensing it through the
                income statement:
                <ol>
                  <li>
                    The reported R&D number each year is added to the overall invested capital amount and then amortised
                    in subsequent years according to an industry-specific amortisation rate.
                  </li>
                  <li>
                    The reported operating profit increases by the R&D amount for that year as it is no longer expense
                    through the income statement.
                  </li>
                </ol>
              </li>
              <li>Exclude goodwill.</li>
              <li>Exclude excess cash, calculated as 98% of cash reported on balance sheet.</li>
            </ul>
            <p>
              The invested capital for the future is projected using the average growth rate of the invested capital
              base over the last 5 years bound by the limits of a minimum 10% growth per year and a maximum of 50%
              growth per year. It is the future returns on this projected invested capital that will drive the value
              creation abilities of the company and ultimately drive its valuation.
            </p>
            <p>
              Given the fact that the invested capital is a key value driver and is projected into the future, it is
              important that some thought is given to how much capital the company is able to invest in the future. A
              few things that may help are:
            </p>
            <ul>
              <li>
                See what the company has invested into in the past and understand how future investment is likely to
                compare to the past.
              </li>
              <li>
                Look at the overall TAM of the market to have an understanding of the upper boundary of invested
                capital.
              </li>
              <li>
                Look at the projected earnings and gauge how much management is likely to invest i.e. what will the
                reinvestment rate/invested capital turnover be?
              </li>
            </ul>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="openDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { excessReturnColors } from "@/components/ValuationCharts/ExcessReturnColor.js"
import numeral from "numeral"
import _ from "lodash"
export default {
  props: ["getChartData"],
  methods: {
    addDescriptionButton(chart) {
      var that = this
      chart.renderer
        .button("Description", 0, 0)
        .attr({
          zIndex: 3
        })
        .on("click", function() {
          that.openDialog = true
        })
        .add()
    }
  },
  data: function() {
    return {
      openDialog: false,
      publicPath: process.env.BASE_URL,
      chartOptions: {
        chart: {
          type: "area",
          zoomType: "x"
        },
        colors: excessReturnColors,
        credits: { enabled: false },
        title: {
          text: "Invested Capital"
        },
        tooltip: {
          xDateFormat: "%Y-%m-%d",
          formatter: function() {
            // The first returned item is the header, subsequent items are the
            // points
            var dt = new Date(this.x).toDateString()
            return ["<b>" + dt + "</b>"].concat(
              this.points
                ? this.points.map(function(point) {
                    return point.series.name + ": " + numeral(point.y).format("0.0a")
                  })
                : []
            )
          },
          split: true
        },
        xAxis: {
          type: "datetime"
        },
        yAxis: {
          title: {
            text: ""
          },
          labels: {
            formatter: function() {
              return numeral(this.value).format("0.0a")
            }
          }
        },
        plotOptions: {
          series: {
            marker: {
              enabled: false
            },
            events: {
              legendItemClick: function() {
                if (_.every(this.chart.series, { visible: true })) {
                  for (let i = 0; i < this.chart.series.length; i++) {
                    if (this.chart.series[i].index != this.index) {
                      this.chart.series[i].setVisible(false, false)
                    }
                  }
                  this.chart.redraw()
                  return false
                }
                if (this.visible && _.countBy(this.chart.series, "visible")["true"] === 1) {
                  for (let i = 0; i < this.chart.series.length; i++) {
                    if (this.chart.series[i].index != this.index) {
                      this.chart.series[i].setVisible(true, false)
                    }
                  }
                  this.chart.redraw()
                  return false
                }
              }
            }
          },
          area: {
            stacking: "normal",
            pointStart: 1940,
            marker: {
              enabled: false,
              symbol: "circle",
              radius: 2,
              states: {
                hover: {
                  enabled: true
                }
              }
            }
          }
        },
        series: [
          // {
          //   name: "Historical",
          //   type: "line",
          //   data: null
          // },
          {
            name: "Projected",
            type: "line",
            data: null,
            dashStyle: "shortdash"
          }
        ]
      }
    }
  },
  async mounted() {
    const cdata = await this.getChartData("invested_capital")

    var timeseries = function(ts) {
      var rs = _.isObject(ts) ? Object.entries(ts) : ts
      var firstValueIndex = 0
      for (var i = 0; i < rs.length; i++) {
        if (rs[i][1] != null) {
          firstValueIndex = i
          break
        }
      }

      return rs.slice(firstValueIndex)
    }

    function toTitleCase(str) {
      return str.replace(/\w\S*/g, function(txt) {
        if (txt.toLowerCase() === "ppe") {
          return "PPE"
        }
        if (txt.toLowerCase() === "r&d") {
          return "R&D"
        }
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      })
    }

    var odata = {
      "total invested capital": null,
      "net working capital": null,
      "net ppe": null,
      goodwill: null,
      intangibles: null,
      "r&d": null
    }
    for (let [key, ts] of Object.entries(cdata.other_ts)) {
      odata[key] = ts
    }

    var keys = Object.keys(odata)
    for (var i = 0; i < keys.length; i++) {
      var key = keys[i]
      var ts = odata[key]
      if (ts) {
        if (key !== "total invested capital") {
          var series = {
            stack: "other_ts",
            zIndex: 2,
            name: toTitleCase(key),
            data: timeseries(ts).map(([k, d]) => [Date.parse(k), d])
          }
          this.chartOptions.series.push(series)
        }

        if (key == "total invested capital") {
          var tseries = {
            stack: "total",
            zIndex: 1,
            name: toTitleCase(key),
            data: timeseries(ts).map(([k, d]) => [Date.parse(k), d])
          }
          this.chartOptions.series.push(tseries)
        }
      }
    }

    //this.chartOptions.series[0].data = timeseries(cdata.historical).map(([k, d]) => [Date.parse(k), d])
    this.chartOptions.series[0].data = timeseries(cdata.Projected).map(([k, d]) => [Date.parse(k), d])
    this.chartOptions.yAxis.title.text = cdata.currency
  }
}
</script>
