<template>
  <div>
    <highcharts
      :options="chartOptions"
      :callback="addDescriptionButton"
      v-if="dataLoaded"
      class="heatmap-chart"
    ></highcharts>
    <v-dialog scrollable max-width="900px" v-model="openDialog">
      <v-card>
        <v-card-title>Fair P/E Grid</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px;width:900px">
          <div class="chart-description">
            <p>
              The grid shows the sensitivity of the fair multiple (using trailing P/E) to assumptions of the growth in
              invested capital and the average ROIIC during the competitive advantage period.
            </p>
            <p>
              The red line shows the cost of capital. Notice how all growth does not create value (even if it means
              increased revenue growth and/or earnings growth); growth at returns below the cost of capital destroys
              value and this is reflected with a lower fair multiple.
            </p>
            <p>
              It is also helpful to notice that with no invested capital growth and with returns at the cost of capital,
              there is not future value creation and the fair multiple is simply the steady state multiple (1 / cost of
              capital).
            </p>
            <p>
              The fair multiples for the entire grid will change should the user adjust either the competitive advantage
              period or the cost of capital.
            </p>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="openDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import sampleData from "@/components/ValuationCharts/PEHeatMapSampleData.json"
import numeral from "numeral"
import _ from "lodash"

export default {
  props: ["getChartData"],
  methods: {
    addDescriptionButton(chart) {
      var that = this
      chart.renderer
        .button("Description", 70, 0)
        .attr({
          zIndex: 3
        })
        .on("click", function() {
          that.openDialog = true
        })
        .add()

      chart.renderer
        .button("Load Data", 850, 0)
        .attr({
          zIndex: 3
        })
        .on("click", async function() {
          await that.loadLatestData()
        })
        .add()
    },
    async loadLatestData() {
      this.loading = true
      this.heatmapData = await this.getChartData("fair_pe_grid_heat_map")
      this.loading = false
      this.showSample = false
    }
  },
  data() {
    var that = this
    return {
      heatmapData: {
        suffix: "x"
      },
      openDialog: false,
      dataLoaded: false,
      loading: false,
      showSample: true,
      defaultChartOptions: {
        chart: {
          type: "heatmap",
          width: 1000,
          plotBorderWidth: 1
        },
        credits: { enabled: false },
        title: {
          text: "Fair P/E Grid - ROIIC v IC GROWTH"
        },
        subtitle: {
          text: ""
        },
        colorAxis: {
          min: -10,
          max: 60,
          minColor: "#f7baba",
          maxColor: "#c1f7ba"
        },
        legend: {
          reversed: true,
          rtl: false,
          align: "right",
          layout: "vertical",
          verticalAlign: "top",
          y: 50,
          symbolHeight: 200
        },
        xAxis: {
          minorGridLineWidth: 0,
          minorTickWidth: 0,
          gridLineWidth: 0,
          title: { text: "ROIIC" },
          labels: {
            formatter: function() {
              return this.value.toFixed(0) + "%"
            }
          },
          plotLines: []
        },
        yAxis: {
          minorGridLineWidth: 0,
          minorTickWidth: 0,
          gridLineWidth: 0,
          reversed: false,
          title: { text: "IC GROWTH" },
          labels: {
            formatter: function() {
              return this.value.toFixed(0) + "%"
            }
          },
          plotLines: []
        },
        tooltip: {
          formatter: function() {
            var res = "<span>IC Growth : " + numeral(this.point.y).format("0.0") + "%</span><br>"
            res += "<span>ROIIC : " + numeral(this.point.x).format("0.0") + "%</span><br>"
            res += "<span>PE : " + numeral(this.point.value).format("0.0") + "x</span><br>"
            return res
          }
        },
        series: [
          {
            type: "heatmap",
            name: "Fair PE",
            colsize: 5,
            rowsize: 5,
            borderWidth: 0,
            data: [],
            dataLabels: {
              enabled: true,
              color: "#000000",
              formatter: function() {
                if (that.showSample) {
                  return ""
                } else {
                  return this.point.value.toFixed(1) + "x"
                }
              }
            }
          }
        ]
      }
    }
  },
  computed: {
    chartOptions: function() {
      var ch = this.defaultChartOptions
      ch.xAxis.plotLines = this.heatmapData.vertical_lines.map((d) => ({
        value: d[0] * 100,
        width: 2,
        zIndex: 100,
        color: d[1],
        dashStyle: "shortdash"
      }))

      ch.yAxis.plotLines = this.heatmapData.horizontal_lines.map((d) => ({
        value: d[0] * 100,
        width: 2,
        zIndex: 100,
        color: d[1],
        dashStyle: "shortdash"
      }))

      var zVal = []
      var data = []
      for (var i = 0; i < this.heatmapData.grid_df.data.length; i++) {
        var row = this.heatmapData.grid_df.data[i]
        for (var j = 0; j < row.length; j++) {
          var x = this.heatmapData.grid_df.columns[j] * 100
          var y = this.heatmapData.grid_df.index[i] * 100
          var z = row[j]
          zVal.push(z)
          data.push([x, y, z])
        }
      }

      ch.series[0].data = data

      var xMin = _.min(this.heatmapData.grid_df.columns) * 100
      var xMax = _.max(this.heatmapData.grid_df.columns) * 100
      ch.series[0].colsize = Math.ceil((xMax - xMin) / this.heatmapData.grid_df.columns.length)

      var yMin = _.min(this.heatmapData.grid_df.index) * 100
      var yMax = _.max(this.heatmapData.grid_df.index) * 100
      ch.series[0].rowsize = Math.ceil((yMax - yMin) / this.heatmapData.grid_df.index.length)

      var zMin = _.min(zVal)
      var zMax = _.max(zVal)

      ch.colorAxis.min = zMin
      ch.colorAxis.max = zMax

      if (this.loading) {
        ch.subtitle.text = "Loading..."
      } else {
        if (this.showSample) {
          ch.subtitle.text = "Click on <b>'Load Data'</b> to fetch latest data."
        } else {
          ch.subtitle.text = this.heatmapData.title
            .substring(this.heatmapData.title.indexOf("INVESTED CAPITAL GROWTH;") + 24)
            .replace(/_/g, " ")
        }
      }
      console.log(ch)
      return ch
    }
  },
  async mounted() {
    this.heatmapData = sampleData
    this.dataLoaded = true
  }
}
</script>

<style>
.heatmap-chart .highcharts-container {
  margin: auto;
}
</style>
