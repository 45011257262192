<template>
  <div>
    <highcharts :options="chartOptions" :callback="addDescriptionButton"></highcharts>
    <v-dialog scrollable max-width="900px" v-model="openDialog">
      <v-card>
        <v-card-title>NOPAT</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px;width:900px">
          <div class="chart-description">
            <p>
              This is the net operating profit after tax and is calculated as follows:
            </p>
          </div>
          <v-card-text class="formula">
            <img :src="`${publicPath}files/formulas/nopat_1.PNG`" />
          </v-card-text>
          <div class="chart-description">
            <p>
              This is interpreted as the earnings at the enterprise level, which are then capitalised at the weighted
              average cost of capital to present us with the steady state. The tax rate is the average of the trailing
              two year tax rate.
            </p>
            <p>
              The projection into future is a second derivative of modelling both invested capital and ROIIC (both
              curved and flat) into the future. Albeit it is not recommended, by-passing the primary value creation
              drivers and looking at the rate of increase/decrease as well as the terminal value at the competitive
              advantage period can provide useful insights into valuation.
            </p>
            <p>
              Moreover, assuming operating margins remain constant, analysts can use the growth of projected NOPAT to
              gauge what revenue growth should look like. Indeed, the absolute revenue numbers can be estimated by
              assuming reversing the tax effect and dividing by the operating margin, which may be particularly useful
              at the terminal value.
            </p>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="openDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { excessReturnColors } from "@/components/ValuationCharts/ExcessReturnColor.js"
import { timeseries } from "@/utils/timeseries.js"
import numeral from "numeral"
export default {
  props: ["getChartData"],
  methods: {
    addDescriptionButton(chart) {
      var that = this
      chart.renderer
        .button("Description", 0, 0)
        .attr({
          zIndex: 3
        })
        .on("click", function() {
          that.openDialog = true
        })
        .add()
    }
  },
  data: function() {
    return {
      openDialog: false,
      publicPath: process.env.BASE_URL,
      chartOptions: {
        chart: {
          type: "area",
          zoomType: "x"
        },
        colors: excessReturnColors,
        credits: { enabled: false },
        title: {
          text: "NOPAT"
        },
        tooltip: {
          xDateFormat: "%Y-%m-%d",
          formatter: function() {
            // The first returned item is the header, subsequent items are the
            // points
            var dt = new Date(this.x).toDateString()
            return ["<b>" + dt + "</b>"].concat(
              this.points
                ? this.points.map(function(point) {
                    return point.series.name + ": " + numeral(point.y).format("0.0a")
                  })
                : []
            )
          },
          split: true
        },
        xAxis: {
          type: "datetime"
        },
        yAxis: {
          title: {
            text: ""
          },
          labels: {
            formatter: function() {
              return numeral(this.value).format("0.0a")
            }
          }
        },
        plotOptions: {
          series: {
            marker: {
              enabled: false
            }
          },
          area: {
            pointStart: 1940,
            marker: {
              enabled: false,
              symbol: "circle",
              radius: 2,
              states: {
                hover: {
                  enabled: true
                }
              }
            }
          }
        },
        series: [
          {
            name: "Historical",
            type: "line",
            data: null
          },
          {
            name: "Historical [Normalised]",
            type: "line",
            data: null,
            visible: false,
            showInLegend: false
          },
          {
            name: "Projected [Curved ROIIC]",
            type: "line",
            data: null,
            dashStyle: "shortdash"
          },
          {
            name: "Projected [Flat ROIIC]",
            type: "line",
            data: null,
            dashStyle: "shortdash"
          }
        ]
      }
    }
  },
  async mounted() {
    const cdata = await this.getChartData("nopat")

    this.chartOptions.series[0].data = timeseries(cdata.raw_nopat.historical).map(([k, d]) => [Date.parse(k), d])
    this.chartOptions.series[2].data = timeseries(cdata.raw_nopat.curved).map(([k, d]) => [Date.parse(k), d])
    this.chartOptions.series[3].data = timeseries(cdata.raw_nopat.flat).map(([k, d]) => [Date.parse(k), d])
    this.chartOptions.yAxis.title.text = cdata.raw_nopat.currency

    if (cdata.model_nopat.currency) {
      this.chartOptions.series[1].visible = true
      this.chartOptions.series[1].showInLegend = true
      this.chartOptions.series[1].data = timeseries(cdata.model_nopat.historical).map(([k, d]) => [Date.parse(k), d])
    }
  }
}
</script>
