<template>
  <div>
    <div v-if="apiError" id="api-error">
      This section of the website is currently down for maintenance. Please check back soon.
    </div>
    <div v-if="!apiError">
      <Toolbar :mailtoAddr="mailtoAddr" class="d-print-none" />
      <v-card>
        <v-card-text style="padding-top:0px;">
          <v-row>
            <v-col cols="12">
              <div class="controls mx-auto">
                <div class="date-options">
                  <table class="summary-table date-options-table">
                    <thead>
                      <tr>
                        <th colspan="2">Date and Options</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Date</td>
                        <td>
                          <v-menu
                            ref="menu1"
                            v-model="datepickPopped"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="100px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="date"
                                placeholder="YYYY-MM-DD format"
                                v-on="on"
                                dense
                                outlined
                                hide-details
                                persistent-hint
                                class="override-highligth"
                                style="background-color:#ffffd1;"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="date"
                              no-title
                              @input="datepickPopped = false"
                              @change="dateChanged()"
                            ></v-date-picker>
                          </v-menu>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <v-radio-group v-model="options" class="options-radio-group">
                            <v-radio class="options-radio" value="default" label="Default" hide-details></v-radio>
                            <v-radio
                              class="options-radio"
                              value="normalise_earnings"
                              label="Normalise Earnings"
                              hide-details
                            ></v-radio>
                            <v-radio
                              class="options-radio"
                              value="capitalise_rd"
                              label="Capitalise R&D"
                              hide-details
                            ></v-radio>
                            <v-radio
                              class="options-radio"
                              value="exclude_all_intangibles"
                              label="Exclude Intangibles"
                              hide-details
                            ></v-radio>
                            <v-radio
                              class="options-radio"
                              value="exclude_excess_cash"
                              label="Exclude Excess Cash"
                              hide-details
                            ></v-radio>
                          </v-radio-group>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="overrides-table-container">
                  <table class="summary-table overrides-table">
                    <thead>
                      <tr>
                        <th style="width: 190px"></th>
                        <th style="width: 64px">Default</th>
                        <th style="width: 64px">Override</th>
                        <th style="width: 64px">Adjusted</th>
                      </tr>
                      <tr>
                        <th colspan="4">Value Creation Drivers</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>ROIIC</td>
                        <td>{{ params.overall_roiic.default }} %</td>
                        <td>
                          <v-text-field
                            v-model="params.overall_roiic.val"
                            suffix="%"
                            outlined
                            hide-details
                            dense
                            name="overall_roiic"
                          ></v-text-field>
                        </td>
                        <td>
                          {{ params.overall_roiic.adjusted }}
                          <span v-if="params.overall_roiic.adjusted">%</span>
                        </td>
                      </tr>
                      <tr>
                        <td>Competitive Advantage Period</td>
                        <td>{{ params.duration.default }} Yrs</td>
                        <td>
                          <v-text-field
                            v-model="params.duration.val"
                            suffix="Yrs"
                            outlined
                            hide-details
                            dense
                            name="duration"
                          ></v-text-field>
                        </td>
                        <td>
                          {{ params.duration.adjusted }}
                          <span v-if="params.duration.adjusted">Yrs</span>
                        </td>
                      </tr>
                      <tr>
                        <td>Invested Capital Growth</td>
                        <td>{{ params.inv_cap_growth.default }} %</td>
                        <td>
                          <v-text-field
                            v-model="params.inv_cap_growth.val"
                            suffix="%"
                            outlined
                            hide-details
                            dense
                            name="inv_cap_growth"
                          ></v-text-field>
                        </td>
                        <td>
                          {{ params.inv_cap_growth.adjusted }}
                          <span v-if="params.inv_cap_growth.adjusted">%</span>
                        </td>
                      </tr>
                      <tr>
                        <td>Returns Profile Steepness</td>
                        <td>{{ params.returns_profile_steepness.default }}</td>
                        <td>
                          <v-text-field
                            v-model="params.returns_profile_steepness.val"
                            outlined
                            hide-details
                            dense
                            name="returns_profile_steepness"
                          ></v-text-field>
                        </td>
                        <td>{{ params.returns_profile_steepness.adjusted }}</td>
                      </tr>
                    </tbody>

                    <thead>
                      <tr>
                        <th colspan="4">Data Overrides</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Cost of Capital/IRR</td>
                        <td>{{ params.coc.default }} %</td>
                        <td>
                          <v-text-field
                            v-model="params.coc.val"
                            suffix="%"
                            outlined
                            hide-details
                            dense
                            name="coc"
                          ></v-text-field>
                        </td>
                        <td>
                          {{ params.coc.adjusted }}
                          <span v-if="params.coc.adjusted">%</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Steady State
                          <span>(m. {{ this.currency }})</span>
                        </td>
                        <td>{{ params.steady_state.default | numeralFormat }}</td>
                        <td>
                          <v-text-field
                            v-model="params.steady_state.val"
                            outlined
                            hide-details
                            dense
                            name="steady_state"
                          ></v-text-field>
                        </td>
                        <td>
                          <span v-if="params.steady_state.adjusted">
                            {{ params.steady_state.adjusted | numeralFormat }}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Invested Capital
                          <span>(m. {{ this.currency }})</span>
                        </td>
                        <td>{{ params.invested_capital.default | numeralFormat }}</td>
                        <td>
                          <v-text-field
                            v-model="params.invested_capital.val"
                            outlined
                            hide-details
                            dense
                            name="invested_capital"
                          ></v-text-field>
                        </td>
                        <td>
                          <span v-if="params.invested_capital.adjusted">
                            {{ params.invested_capital.adjusted | numeralFormat }}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>Current ROIIC</td>
                        <td>{{ params.current_roiic.default }} %</td>
                        <td>
                          <v-text-field
                            v-model="params.current_roiic.val"
                            suffix="%"
                            outlined
                            hide-details
                            dense
                            name="current_roiic"
                          ></v-text-field>
                        </td>
                        <td>
                          {{ params.current_roiic.adjusted }}
                          <span v-if="params.current_roiic.adjusted">%</span>
                        </td>
                      </tr>
                    </tbody>
                    <thead>
                      <tr>
                        <th colspan="4">Valuation</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          Price
                          <span>({{ this.currency }})</span>
                        </td>
                        <td>{{ params.price.default | numeralFormat }}</td>
                        <td>{{ priceChange }}</td>
                        <td>
                          <span>{{ adjustedPrice | numeralFormat }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="solve-for">
                  <div>
                    <v-switch
                      v-model="params.adjust_cost_of_capital_historically.val"
                      :label="`Adjust Cost of Capital Historically`"
                      style="margin-top:0px;margin-bottom:0px;padding-top:0px;"
                      class="switch-option"
                    ></v-switch>

                    <v-switch
                      v-model="params.adjust_steady_state_historically.val"
                      :label="`Adjust Steady State Historically`"
                      style="margin-top:0px;margin-bottom:0px;padding-top:0px;"
                      class="switch-option"
                    ></v-switch>

                    <v-switch
                      v-model="params.adjust_invested_capital_historically.val"
                      :label="`Adjust Invested Capital Historically`"
                      style="margin-top:0px;margin-bottom:0px;padding-top:0px;"
                      class="switch-option"
                    ></v-switch>

                    <v-select
                      v-model="params.solve_for_market_price.val"
                      :items="params.solve_for_market_price.options"
                      label="Solve for Market Price"
                      clearable
                    ></v-select>
                  </div>

                  <div>
                    <v-btn v-on:click="resetDefaults()" style="margin-top:0px; width: 93px;">Reset</v-btn>
                    <v-btn v-on:click="updateComponents()" style="margin-top:0px;margin-left:10px; width: 93px;">
                      Update
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2" style="padding:0"></v-col>
            <v-col cols="8" style="padding:0">
              <Narrative :key="componentTS + bedrock_id" v-if="narrativeData" :cdata="narrativeData" />
            </v-col>
            <v-col cols="2" style="padding:0"></v-col>
            <!-- <v-col cols="1" style="padding:0">
              <a :href="mailtoAddr" style="text-decoration:none">
                <v-btn class="ma-2" title="provide feedback via email">
                  <v-icon dark>mdi-email-alert-outline</v-icon>
                </v-btn>
              </a>
            </v-col> -->
          </v-row>
        </v-card-text>
      </v-card>

      <v-row dense>
        <v-col cols="12" md="6">
          <v-card outlined>
            <v-card-text>
              <HistoricalDynamic
                :key="componentTS + bedrock_id"
                :getChartData="getChartData"
                v-if="higherPiorityDataLoaded"
              />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="6">
          <v-card outlined>
            <v-card-text>
              <SteadyStateFuture
                :key="componentTS + bedrock_id"
                :getChartData="getChartData"
                v-if="higherPiorityDataLoaded"
              />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="6">
          <v-card outlined>
            <v-card-text>
              <InvestedCapital
                :key="componentTS + bedrock_id"
                :getChartData="getChartData"
                v-if="higherPiorityDataLoaded"
              />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="6">
          <v-card outlined>
            <v-card-text>
              <Roiic :key="componentTS + bedrock_id" :getChartData="getChartData" v-if="higherPiorityDataLoaded" />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="6">
          <v-card outlined>
            <v-card-text>
              <Nopat :key="componentTS + bedrock_id" :getChartData="getChartData" v-if="higherPiorityDataLoaded" />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="6">
          <v-card outlined>
            <v-card-text>
              <Roic :key="componentTS + bedrock_id" :getChartData="getChartData" v-if="higherPiorityDataLoaded" />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="3">
          <v-card outlined>
            <v-card-text>
              <ValueBreakdown
                :key="componentTS + bedrock_id"
                :cdata="breakdownData"
                section="user"
                v-if="breakdownData"
              />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="3">
          <v-card outlined>
            <v-card-text>
              <ValueBreakdown
                :key="componentTS + bedrock_id"
                :cdata="breakdownData"
                section="market"
                v-if="breakdownData"
              />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="3">
          <v-card outlined>
            <v-card-text>
              <ValueBreakdown
                :key="componentTS + bedrock_id"
                :cdata="breakdownData"
                section="history"
                v-if="breakdownData"
              />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="3">
          <v-card outlined>
            <v-card-text>
              <ValueBreakdown
                :key="componentTS + bedrock_id"
                :cdata="breakdownData"
                section="sector"
                :getChartData="getChartData"
                v-if="breakdownData"
              />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="6">
          <v-card outlined>
            <v-card-text>
              <HistoricalValuationIndex
                :key="componentTS + bedrock_id"
                :getChartData="getChartData"
                v-if="higherPiorityDataLoaded"
              />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="6">
          <v-card outlined>
            <v-card-text>
              <AggregateValuationIndex
                :key="componentTS + bedrock_id"
                :getChartData="getChartData"
                v-if="higherPiorityDataLoaded"
              />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="12">
          <v-card outlined>
            <v-card-text>
              <PEHeatMap :key="componentTS + bedrock_id" :getChartData="getChartData" v-if="higherPiorityDataLoaded" />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="12">
          <v-card outlined>
            <v-card-text>
              <CompareChart
                :key="componentTS + bedrock_id"
                :getChartData="getChartData"
                :security="security"
                v-if="higherPiorityDataLoaded"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-snackbar v-model="snackbar" multi-line top color="error">
      {{ snackbarMsg }}
    </v-snackbar>
  </div>
</template>

<script>
import _ from "lodash"
import Narrative from "@/components/ValuationCharts/Narrative.vue"
import HistoricalDynamic from "@/components/ValuationCharts/HistoricalDynamic.vue"
import SteadyStateFuture from "@/components/ValuationCharts/SteadyStateFuture.vue"
import InvestedCapital from "@/components/ValuationCharts/InvestedCapital.vue"
import Roiic from "@/components/ValuationCharts/Roiic.vue"
import Roic from "@/components/ValuationCharts/Roic.vue"
import Nopat from "@/components/ValuationCharts/Nopat.vue"
import ValueBreakdown from "@/components/ValuationCharts/ValueBreakdown.vue"
import PEHeatMap from "@/components/ValuationCharts/PEHeatMap.vue"
import HistoricalValuationIndex from "@/components/ValuationCharts/HistoricalValuationIndex.vue"
import AggregateValuationIndex from "@/components/ValuationCharts/AggregateValuationIndex.vue"
import CompareChart from "@/components/ValuationCharts/CompareChart.vue"
import Toolbar from "@/components/ValuationCharts/Toolbar.vue"

export default {
  props: {
    id: { type: String, requried: true }
  },
  components: {
    Narrative,
    HistoricalDynamic,
    SteadyStateFuture,
    InvestedCapital,
    Roiic,
    Roic,
    Nopat,
    ValueBreakdown,
    PEHeatMap,
    HistoricalValuationIndex,
    AggregateValuationIndex,
    CompareChart,
    Toolbar
  },

  data() {
    return {
      requests: {},
      snackbar: false,
      snackbarMsg: "",
      apiError: false,
      showCompare: false,
      showSettings: false,
      breakdownData: null,
      narrativeData: null,
      dateFormatted: this.isoDate(new Date()),
      datepickPopped: false,
      date: this.isoDate(new Date()),
      bedrock_id: null,
      security: null,
      higherPiorityDataLoaded: false,
      componentTS: Date.now(),
      currency: "Currency",
      options: "default",
      params: {
        overall_roiic: {
          val: null,
          type: "percent",
          default: null,
          alias: "return on incremental invested capital",
          adjusted: null
        },
        current_roiic: { val: null, type: "percent", default: null, alias: "current roiic", adjusted: null },
        coc: { val: null, type: "percent", default: null, alias: "cost of capital", adjusted: null },
        duration: { val: null, type: "number", default: null, alias: "duration", adjusted: null },
        invested_capital: { val: null, type: "currency", default: null, alias: "invested capital", adjusted: null },
        inv_cap_growth: { val: null, type: "percent", default: null, alias: "invested capital growth", adjusted: null },
        returns_profile_steepness: { val: null, type: "number", default: "1", alias: null, min: 0, adjusted: null },
        steady_state: { val: null, type: "currency", default: null, alias: "steady state", adjusted: null },
        price: { val: null, type: "currency", default: null, alias: "price", adjusted: null },
        adjust_cost_of_capital_historically: {
          val: true,
          type: "boolean",
          default: true,
          alias: "adjust_cost_of_capital_historically",
          adjusted: null,
          initial_val: true
        },
        adjust_steady_state_historically: {
          val: true,
          type: "boolean",
          default: true,
          alias: "adjust_steady_state_historically",
          adjusted: null,
          initial_val: true
        },
        adjust_invested_capital_historically: {
          val: true,
          type: "boolean",
          default: true,
          alias: "adjust_invested_capital_historically",
          adjusted: null,
          initial_val: true
        },
        solve_for_market_price: {
          val: null,
          type: "options",
          default: false,
          alias: "solve_for_market_price",
          adjusted: null,
          options: [
            { text: "Return on Incremental Invested Capital", value: "Return on Incremental Invested Capital" },
            { text: "Cost of Capital/IRR", value: "Cost of Capital" },
            { text: "Competitive Advantage Period", value: "Competitive Advantage Period" },
            { text: "Invested Capital Growth", value: "Invested Capital Growth" }
          ]
        },
        resetAdjusted: function() {
          var keys = Object.keys(this)
          for (const p of keys) {
            if (this[p] && this[p].adjusted) {
              this[p].adjusted = null
            }
          }
        },
        mapDefault: function(alias, defaultVal) {
          console.log(alias)
          var p = _.find(this, { alias: alias })
          if (p) {
            if (alias == "invested capital" || alias == "steady state") {
              p.default = Math.round(defaultVal / 1000000)
            } else {
              p.default = defaultVal
            }
          }
        },
        mapValues: function(alias, val) {
          var p = _.find(this, { alias: alias })
          if (p) {
            var _val = alias == "invested capital" || alias == "steady state" ? Math.round(val / 1000000) : val
            if (_val != p.default) {
              p.val = _val
            }
          }
        },
        mapAdjusted: function(alias, val) {
          var p = _.find(this, { alias: alias })
          if (p) {
            var _adjusted = alias == "invested capital" || alias == "steady state" ? Math.round(val / 1000000) : val
            if (_adjusted != p.default) {
              p.adjusted = _adjusted
            }
          }
        }
      }
    }
  },
  async mounted() {
    const api = await this.makeAPIClient(this.QUANT_API_HOST)
    const response = await api.get(`/securities/GetSecurityMapping?security_id=${this.id}`)
    const sdata = response.data
    this.bedrock_id = sdata[0].bedrock_id
    this.security = sdata[0]
    this.updateChartData()
    this.updateDefaultValues()
  },
  computed: {
    qs() {
      var qs = "?"
      Object.entries(this.params).forEach(([name, obj]) => {
        if (obj.val != null && obj.val.toString().length > 0 && obj.val != obj.default) {
          var _val = obj.val
          _val = obj.alias == "invested capital" || obj.alias == "steady state" ? Math.round(_val * 1000000) : _val
          var pval = obj.type == "percent" ? (parseFloat(_val) / 100).toString() : _val.toString()
          qs += "&" + name + "=" + pval.toLowerCase()
        }
      })
      if (this.options != "default") {
        qs += "&" + this.options + "=true"
      }
      return qs
    },
    mailtoAddr() {
      var body = this.bedrock_id + " " + this.date + " " + this.qs.replace("?", "").replace(/&/g, " ")
      return "mailto:GGrewal@williamblair.com?subject=Excess Return Tab Feedback&body=" + body
    },
    priceChange() {
      if (this.params.price.adjusted) {
        var _val = Math.round((this.params.price.adjusted / this.params.price.default - 1) * 100)
        if (_val != 0) {
          if (_val > 0) {
            return "+" + _val + "%"
          }
          return _val + "%"
        } else {
          return ""
        }
      }
      return ""
    },
    adjustedPrice() {
      if (this.params.price.adjusted) {
        return this.params.price.adjusted
      }
      return this.params.price.default
    }
  },
  methods: {
    async updateDefaultValues() {
      var defaults = await this.getChartData("auto_adjusted_values")
      Object.entries(defaults.auto_values).map(([k, d]) => {
        if (k === "currency") {
          this.currency = d
        } else {
          this.params.mapDefault(k, d)
        }
      })
    },
    async updateAdjusted() {
      var defaults = await this.getChartData("auto_adjusted_values")
      this.params.resetAdjusted()
      Object.entries(defaults.adjusted_values).map(([k, d]) => {
        if (k === "currency") {
          this.currency = d
        } else {
          this.params.mapAdjusted(k, d)
        }
      })
    },
    onNumberClick(ev) {
      if (!ev.target.value) {
        ev.target.value = this.params[ev.target.name].default
      }
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split("-")
      return `${month}/${day}/${year}`
    },
    parseDate(date) {
      if (!date) return null

      const [month, day, year] = date.split("/")
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`
    },
    isoDate(date) {
      if (!date) return null

      return date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2)
    },
    async getChartData(path) {
      var that = this
      const sUrl = "/api/v1.0/" + path + "/" + this.bedrock_id + "/" + this.date + this.qs
      const api = await this.makeAPIClient(this.CHART_API_HOST)
      const response = await api.get(sUrl).catch(function(error) {
        that.enabledUpdate()
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.data && error.response.data["Error Message"]) {
            that.snackbarMsg = error.response.data["Error Message"]
          } else {
            that.snackbarMsg = "There was an API error."
          }

          that.snackbar = true
        }
        console.log(error.config)
      })
      const cdata = response.data
      return cdata
    },
    dateChanged() {
      this.updateDefaultValues()
    },
    resetDefaults() {
      this.enabledUpdate()
      // eslint-disable-next-line no-unused-vars
      Object.entries(this.params).forEach(([name, obj]) => {
        if (obj.val != null && obj.val.toString().length > 0) {
          obj.val = obj.initial_val || null
        }
        obj.adjusted = null
      })

      this.dateFormatted = this.isoDate(new Date())
      this.date = this.isoDate(new Date())

      if (this.options != "default") {
        this.options = "default"
      }
      this.updateDefaultValues()
    },
    async updateComponents() {
      //  await this.updateDefaultValues()
      await this.updateAdjusted()
      await this.updateChartData()
      this.componentTS = Date.now()
    },
    async updateChartData() {
      this.narrativeData = null
      this.breakdownData = null
      try {
        this.narrativeData = await this.getChartData("summary_text")
      } catch (e) {
        this.apiError = true
      }
      this.higherPiorityDataLoaded = true
      this.breakdownData = await this.getChartData("theoretical_prices_breakdown")
    }
  }
}
</script>

<style>
.v-text-field__slot input {
  text-align: right;
}
.options-radio {
  margin: 0px;
  font-size: 10px;
}

.options-radio-group {
  margin-top: 0px !important;
}

.switch-option {
  margin-top: 0px;
  margin-bottom: 0px;
}

.v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
  margin-bottom: 1px !important;
}

.overline {
  margin-bottom: 5px;
}

#api-error {
  padding: 20px;
}

.formula {
  text-align: center;
  padding: 0px;
}

.overrides-table {
  min-height: 230px;
  width: 600px;
}

.overrides-table td:nth-child(2) {
  text-align: center !important;
  padding-right: 7px;
  min-width: 80px;
}

.overrides-table td:nth-child(3) .v-input {
  background-color: #ffffd1;
  border: none;
}

.override-highligth {
  background-color: #ffffd1;
}

.date-options-table {
  margin-right: 0px;
  min-height: 230px;
}

.switch-option .v-input__slot {
  margin: 0px;
}

.controls {
  width: 1270px;
}

.solve-for {
  width: 300px;
  float: right;
  margin-right: 50px;
}

.overrides-table-container {
  display: inline;
  float: left;
}

.date-options {
  width: 290px;
  float: left;
}
</style>
