<template>
  <div>
    <highcharts :options="chartOptions" :callback="addButtons"></highcharts>
    <v-dialog scrollable max-width="900px" v-model="openDialog">
      <v-card>
        <v-card-title>Value breakdown {{ sectionDisplay }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px;width:900px">
          <div class="chart-description">
            <p>
              The value breakdown waterfall chart shows the components that make up the equity valuation of the company.
              It is calculated as
            </p>
          </div>
          <v-card-text class="formula">
            <img :src="`${publicPath}files/formulas/value_breakdown_1.PNG`" />
          </v-card-text>
          <div class="chart-description">
            <p v-if="section == 'user'">
              This chart shows the breakdown of the equity valuation given the user inputs. If no inputs are specified,
              this defaults to the current market values
            </p>
            <p v-if="section == 'market'">
              This chart shows the breakdown of the equity valuation given the market prices; the equity value here is
              the market cap.
            </p>
            <p v-if="section == 'history'">
              This chart shows the breakdown of the equity valuation given the historical level of returns. This answers
              the question: what is the fair value of the company if the returns it generates for a competitive
              advantage period of five years are at the same levels as the previous five years?
            </p>
            <p v-if="section == 'sector'">
              This chart shows the breakdown of the equity valuation given the historical level of returns. This answers
              the question: what is the fair value of the company if the returns it generates for a competitive
              advantage period of five years are at the same levels as those currently implied by the sector?
            </p>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="openDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog scrollable max-width="900px" v-model="openPeersDialog">
      <v-card>
        <v-card-title>Peers List - {{ sectionDisplay }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 500px;width:900px">
          <div class="peers">
            <PeersList :getChartData="getChartData" />
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="openPeersDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { ExcessReturnColor } from "@/components/ValuationCharts/ExcessReturnColor.js"
import PeersList from "@/components/ValuationCharts/PeersList"
import numeral from "numeral"
function titleCase(str) {
  str = str.toLowerCase().split(" ")
  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1)
  }
  return str.join(" ")
}
export default {
  props: ["cdata", "section", "getChartData"],
  components: {
    PeersList
  },
  methods: {
    addButtons(chart) {
      var that = this
      chart.renderer
        .button("Description", 0, 0)
        .attr({
          zIndex: 3
        })
        .on("click", function() {
          that.openDialog = true
        })
        .add()

      if (that.section == "sector") {
        chart.renderer
          .button("Peers", chart.plotWidth - 30, 0)
          .attr({
            zIndex: 3
          })
          .on("click", function() {
            that.openPeersDialog = true
          })
          .add()
      }
    }
  },
  data: function() {
    return {
      openDialog: false,
      openPeersDialog: false,
      sectionDisplay: null,
      publicPath: process.env.BASE_URL,
      chartOptions: {
        chart: {
          height: 300
        },
        tooltip: {
          formatter: function() {
            return "The value for <b>" + this.x + "</b> is <b>" + numeral(this.y).format("0a") + "</b>"
          }
        },
        title: {
          text: ""
        },
        subtitle: {
          text: ""
        },
        credits: { enabled: false },
        xAxis: {
          categories: []
        },
        legend: {
          enabled: false
        },
        plotOptions: {
          series: {
            colorByPoint: true,
            events: {
              show: function() {
                var chart = this.chart,
                  series = chart.series,
                  i = series.length,
                  otherSeries

                while (i--) {
                  otherSeries = series[i]
                  if (otherSeries != this && otherSeries.visible) {
                    otherSeries.hide()
                  }
                }
              },
              legendItemClick: function() {
                if (this.visible) {
                  return false
                }
              }
            }
          }
        },
        yAxis: {
          min: 0,
          max: 0,
          title: {
            text: ""
          },
          labels: {
            formatter: function() {
              return numeral(this.value).format("0.0a")
            }
          }
        },
        series: []
      }
    }
  },
  async mounted() {
    const cdata = this.cdata

    if (cdata && cdata.plot_df) {
      var yAxisMax = 0
      var yAxisMin = 0
      for (var i = 0; i < cdata.plot_df.columns.length; i++) {
        var item = cdata.plot_df.columns[i]
        var s = {
          type: "waterfall",
          name: titleCase(item),
          data: []
        }
        var yAxisSum = 0
        for (var j = 0; j < cdata.plot_df.index.length; j++) {
          var d = titleCase(cdata.plot_df.index[j].replace("_", " "))
          this.chartOptions.xAxis.categories.push(d)
          s.data.push({
            name: d,
            y: cdata.plot_df.data[j][i],
            color: ExcessReturnColor(d)
          })
          yAxisSum += cdata.plot_df.data[j][i]
          if (yAxisSum > yAxisMax) {
            yAxisMax = yAxisSum
          }
          if (yAxisSum < yAxisMin) {
            yAxisMin = yAxisSum
          }
        }
        s.data.push({
          name: "Equity Value",
          isSum: true,
          color: ExcessReturnColor("Equity Value")
        })
        this.chartOptions.xAxis.categories.push("Equity Value")

        if (this.section) {
          s.visible = this.section == item
        } else {
          s.visible = i == 0
        }

        this.chartOptions.series.push(s)
      }
      this.chartOptions.yAxis.max = yAxisMax
      this.chartOptions.yAxis.min = yAxisMin
      this.sectionDisplay = titleCase(this.section)
      this.chartOptions.title.text = titleCase(this.section)
      this.chartOptions.subtitle.text = titleCase(cdata.title)
      this.chartOptions.yAxis.title.text = cdata.currency
    }
  }
}
</script>
