<template>
  <div>
    <highcharts :options="chartOptions" :callback="addDescriptionButton"></highcharts>
    <v-dialog scrollable max-width="900px" v-model="openDialog">
      <v-card>
        <v-card-title>ROIC</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px;width:900px">
          <div class="chart-description">
            <p>
              The ROIC metric uses NOPAT as the numerator and the calculated invested capital as the denominator. It is
              therefore a second derivative of the key value drivers of the model (invested capital and ROIIC) but
              provide useful insight for analysts, especially when looking at the terminal ROIC level.
            </p>
            <p>
              This metric can also be used to help understand the ROIIC metric better. Since ROIC is a backwards-looking
              measure, by using the total level of earnings and invested capital, as we project into the future, the
              ROIC level, being nothing more than the average of ROIIC at every time interval, will move over time
              towards the average ROIIC level implied by the model.
            </p>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="openDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { excessReturnColors } from "@/components/ValuationCharts/ExcessReturnColor.js"
import { timeseries } from "@/utils/timeseries.js"
import numeral from "numeral"
export default {
  props: ["getChartData"],
  methods: {
    addDescriptionButton(chart) {
      var that = this
      chart.renderer
        .button("Description", 0, 0)
        .attr({
          zIndex: 3
        })
        .on("click", function() {
          that.openDialog = true
        })
        .add()
    }
  },
  data: function() {
    return {
      openDialog: false,
      chartOptions: {
        chart: {
          type: "area",
          zoomType: "x"
        },
        colors: excessReturnColors,
        credits: { enabled: false },
        title: {
          text: "ROIC"
        },
        tooltip: {
          xDateFormat: "%Y-%m-%d",
          formatter: function() {
            // The first returned item is the header, subsequent items are the
            // points
            var dt = new Date(this.x).toDateString()
            return ["<b>" + dt + "</b>"].concat(
              this.points
                ? this.points.map(function(point) {
                    return point.series.name + ": " + numeral(point.y).format("0%")
                  })
                : []
            )
          },
          split: true
        },
        xAxis: {
          type: "datetime"
        },
        yAxis: {
          title: {
            text: ""
          },
          labels: {
            formatter: function() {
              return numeral(this.value).format("0%")
            }
          }
        },
        plotOptions: {
          series: {
            marker: {
              enabled: false
            }
          },
          area: {
            pointStart: 1940,
            marker: {
              enabled: false,
              symbol: "circle",
              radius: 2,
              states: {
                hover: {
                  enabled: true
                }
              }
            }
          }
        },
        series: [
          {
            name: "Historical",
            type: "line",
            data: null
          },
          {
            name: "Projected [Curved ROIC]",
            type: "line",
            data: null,
            dashStyle: "shortdash"
          },
          {
            name: "Projected [Flat ROIC]",
            type: "line",
            data: null,
            dashStyle: "shortdash"
          }
        ]
      }
    }
  },
  async mounted() {
    const cdata = await this.getChartData("roic")

    this.chartOptions.series[0].data = timeseries(cdata.historical).map(([k, d]) => [Date.parse(k), d])
    this.chartOptions.series[1].data = timeseries(cdata.curved).map(([k, d]) => [Date.parse(k), d])
    this.chartOptions.series[2].data = timeseries(cdata.flat).map(([k, d]) => [Date.parse(k), d])
  }
}
</script>
