<template>
  <div>
    <v-row align="center">
      <v-col class="d-flex" cols="4" sm="3"></v-col>
      <v-col class="d-flex" cols="4" sm="3">
        <v-autocomplete
          ref="autocomplete"
          v-model="select"
          :items="securityList"
          :search-input.sync="search"
          :menu-props="{ maxHeight: 500 }"
          no-filter
          solo-inverted
          single-line
          dense
          flat
          clearable
          placeholder="Compare to.. type name or ticker"
          auto-select-first
          hide-no-data
          hide-details
          item-value="imdwID_RAW"
          item-text="name"
          @click="search = ''"
          @focus="search = ''"
          @change="goto"
        >
          <template v-slot:item="{ item: { BBID_RAW, name } }">
            <div class="autocomplete-item">
              <div class="autocomplete-item__ticker" v-text="name"></div>
              <div class="autocomplete-item__name" v-text="BBID_RAW"></div>
            </div>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col class="d-flex" cols="3" sm="3">
        <v-select
          :items="displayMetrics"
          label="Select Metric"
          v-model="selectedMetric"
          @change="updateMetric"
        ></v-select>
      </v-col>
    </v-row>
    <div>
      <highcharts :options="chartOptions" class="compare-chart" :callback="addDescriptionButton"></highcharts>
      <v-dialog scrollable max-width="900px" v-model="openDialog">
        <v-card>
          <v-card-title>Comparison</v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height: 300px;width:900px">
            <div class="chart-description">
              <p>
                This allows comparison for the current stock with any other stock for particular fields. This can be
                very useful especially when trying to get a firmer grasp on the projection into the future and what the
                market is implying.
              </p>
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="blue darken-1" text @click="openDialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { excessReturnColors } from "@/components/ValuationCharts/ExcessReturnColor.js"
import { timeseries } from "@/utils/timeseries.js"
import numeral from "numeral"
function titleCase(str) {
  str = str.toLowerCase().split(" ")
  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1)
  }
  return str.join(" ")
}
export default {
  props: ["getChartData", "security"],
  data: function() {
    return {
      openDialog: false,
      securityList: [],
      compareSec: null,
      search: null,
      select: "",
      secInfo: null,
      bedrock_id: null,
      securityCurrecny: null,
      selectedMetric: "INVESTED_CAPITAL",
      displayMetrics: [],
      metrics: [
        "RETURN_ON_INCREMENTAL_INVESTED_CAPITAL",
        "RETURN_ON_INVESTED_CAPITAL",
        "INVESTED_CAPITAL",
        "COST_OF_EQUITY",
        "COST_OF_CAPITAL",
        "FUTURE_VALUE_PERCENTAGE",
        "NOPAT",
        "EBIT",
        "FUTURE_VALUE",
        "STEADY_STATE",
        "BETA",
        "MARKET_CAP",
        "CASH",
        "TOTAL_DEBT",
        "R_AND_D",
        "GOODWILL"
      ],
      chartOptions: {
        chart: {
          type: "area",
          zoomType: "x",
          width: 1000
        },
        colors: excessReturnColors,
        credits: { enabled: false },
        title: {
          text: "Invested Capital"
        },
        tooltip: {
          xDateFormat: "%Y-%m-%d",
          formatter: function() {
            // The first returned item is the header, subsequent items are the
            // points
            var dt = new Date(this.x).toDateString()
            return ["<b>" + dt + "</b>"].concat(
              this.points
                ? this.points.map(function(point) {
                    var fmt = point.point.options.numeralFmt
                    return point.series.name + ": " + numeral(point.y).format(fmt)
                  })
                : []
            )
          },
          split: true
        },
        xAxis: {
          type: "datetime"
        },
        yAxis: [
          {
            title: {
              text: ""
            },
            labels: {
              formatter: function() {
                return numeral(this.value).format("0.0a")
              }
            }
          },
          {
            opposite: true,
            title: {
              text: ""
            },
            labels: {
              formatter: function() {
                return numeral(this.value).format("0.0a")
              }
            }
          }
        ],
        plotOptions: {
          series: {
            marker: {
              enabled: false
            }
          },
          area: {
            pointStart: 1940,
            marker: {
              enabled: false,
              symbol: "circle",
              radius: 2,
              states: {
                hover: {
                  enabled: true
                }
              }
            }
          }
        },
        series: [
          {
            name: this.security.issuer_name,
            type: "line",
            data: null
          },
          {
            yAxis: 1,
            name: "",
            type: "line",
            data: null,
            visible: false,
            showInLegend: false
          }
        ]
      }
    }
  },
  async mounted() {
    this.displayMetrics = this.metrics.sort().map((item) => {
      return {
        value: item,
        text: titleCase(item.toLowerCase().replace(/_/g, " "))
      }
    })
    this.updateSecuritySeries()
  },
  watch: {
    async search(value, old) {
      if (!value || value === old) return
      const api = await this.makeAPIClient(this.API_HOST)
      const response = await api.get(`/security?q=${value}`)
      this.securityList = response.data
    }
  },
  methods: {
    async goto(id) {
      if (id === undefined) return
      const api = await this.makeAPIClient(this.API_HOST)
      const response = await api.get(`/security-info?id=${id}`)
      this.secInfo = await response.data

      const quantApi = await this.makeAPIClient(this.QUANT_API_HOST)
      const resp = await quantApi.get(`/securities/GetSecurityMapping?security_id=${id}`)
      const sdata = resp.data
      this.compareSec = sdata[0]
      this.updateCompareSeries()
    },
    addDescriptionButton(chart) {
      var that = this
      chart.renderer
        .button("Description", 45, 0)
        .attr({
          zIndex: 3
        })
        .on("click", function() {
          that.openDialog = true
        })
        .add()
    },
    async updateCompareSeries() {
      if (this.compareSec) {
        const chartApi = await this.makeAPIClient(this.CHART_API_HOST)
        const res = await chartApi.get("/api/v1.0/field/" + this.compareSec.bedrock_id + "/" + this.selectedMetric)
        const cdata = await res.data
        if (this.securityCurrecny != cdata.currency) {
          this.chartOptions.yAxis[1].title.text = cdata.currency
        } else {
          this.chartOptions.series[1].yAxis = 0
        }
        var fmt = cdata.is_percentage ? "0%" : "0a"
        this.chartOptions.yAxis[1].labels.formatter = function() {
          return numeral(this.value).format(fmt)
        }
        this.chartOptions.series[1].visible = true
        this.chartOptions.series[1].showInLegend = true
        this.chartOptions.series[1].name = this.compareSec.issuer_name
        this.chartOptions.series[1].data = timeseries(cdata.data).map(([k, d]) => {
          return { x: Date.parse(k), y: d, numeralFmt: fmt }
        })
      }
    },

    async updateSecuritySeries() {
      const chartApi = await this.makeAPIClient(this.CHART_API_HOST)
      const response = await chartApi.get("/api/v1.0/field/" + this.security.bedrock_id + "/" + this.selectedMetric)
      const cdata = await response.data
      this.securityCurrecny = cdata.currency
      this.chartOptions.yAxis[0].title.text = cdata.currency
      var fmt = cdata.is_percentage ? "0%" : "0a"
      this.chartOptions.yAxis[0].labels.formatter = function() {
        return numeral(this.value).format(fmt)
      }

      this.chartOptions.series[0].data = Object.entries(cdata.data)
        // eslint-disable-next-line no-unused-vars
        .filter(([k, d]) => d != null)
        .map(([k, d]) => {
          return { x: Date.parse(k), y: d, numeralFmt: fmt }
        })
    },

    async updateMetric() {
      this.updateCompareSeries()
      this.updateSecuritySeries()
      this.chartOptions.title.text = titleCase(this.selectedMetric.toLowerCase().replace(/_/g, " "))
    }
  }
}
</script>
<style>
.compare-chart .highcharts-container {
  margin: auto;
}
</style>
