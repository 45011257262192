<template>
  <div>
    <div style="color:black" v-if="!showDetail">
      <span v-html="text_teaser"></span>
      <a style="margin-left:5px; text-decoration:underline" @click="showDetail = true">Show Narrative..</a>
    </div>
    <div style="color:black" v-if="showDetail">
      <p v-html="text_row_1"></p>
      <p v-html="text_row_2"></p>
      <p v-html="text_row_3"></p>
      <p v-html="text_row_4"></p>
      <a style="margin-left:5px; text-decoration:underline" @click="showDetail = false">Hide Narrative</a>
    </div>
  </div>
</template>

<script>
export default {
  props: ["cdata"],
  data: function() {
    return {
      showDetail: false,
      text_teaser: "",
      text_row_1: "",
      text_row_2: "",
      text_row_3: "",
      text_row_4: ""
    }
  },
  async mounted() {
    const cdata = this.cdata

    this.text_teaser = cdata.text_row_1.substring(0, 100)
    this.text_row_1 = cdata.text_row_1
    this.text_row_2 = cdata.text_row_2
    this.text_row_3 = cdata.text_row_3
    this.text_row_4 = cdata.text_row_4
  }
}
</script>
