<template>
  <div>
    <highcharts :options="chartOptions" :callback="addDescriptionButton"></highcharts>
    <v-dialog scrollable max-width="900px" v-model="openDialog">
      <v-card>
        <v-card-title>Steady State vs. Future Value</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px;width:900px">
          <div class="chart-description">
            <p>
              This chart plots the relative amounts of steady state and future value implied by the stock price over
              time.
            </p>
            <p>
              When the level of steady state is greater than 100%, this implies the market is pricing negative future
              value i.e. future value destruction.
            </p>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="openDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ExcessReturnColor } from "@/components/ValuationCharts/ExcessReturnColor.js"
import { timeseries } from "@/utils/timeseries.js"
import numeral from "numeral"
export default {
  props: ["getChartData"],
  methods: {
    addDescriptionButton(chart) {
      var that = this
      chart.renderer
        .button("Description", 0, 0)
        .attr({
          zIndex: 3
        })
        .on("click", function() {
          that.openDialog = true
        })
        .add()
    }
  },
  data: function() {
    return {
      openDialog: false,
      chartOptions: {
        chart: {
          type: "area",
          zoomType: "x"
        },
        credits: { enabled: false },
        title: {
          text: ""
        },
        tooltip: {
          xDateFormat: "%Y-%m-%d",
          formatter: function() {
            // The first returned item is the header, subsequent items are the
            // points
            var dt = new Date(this.x).toDateString()
            return ["<b>" + dt + "</b>"].concat(
              this.points
                ? this.points.map(function(point) {
                    return point.series.name + ": " + numeral(point.y / 100).format("0%")
                  })
                : []
            )
          },
          split: true
        },
        xAxis: {
          type: "datetime"
        },
        yAxis: {
          title: {
            text: ""
          },
          labels: {
            formatter: function() {
              return numeral(this.value / 100).format("0%")
            }
          }
        },
        plotOptions: {
          area: {
            stacking: "normal",
            lineWidth: 0
          },
          series: {
            marker: {
              enabled: false
            }
          }
        },
        series: [
          {
            name: "Future Value",
            type: "area",
            data: null,
            color: ExcessReturnColor("Future Value")
          },
          {
            name: "Steady State",
            type: "area",
            data: null,
            color: ExcessReturnColor("Steady State")
          }
        ]
      }
    }
  },
  async mounted() {
    const cdata = await this.getChartData("steadystate_futurevalue")

    this.chartOptions.series[1].data = timeseries(cdata.fv_perc).map(([k, d]) => [Date.parse(k), 100 - d])
    this.chartOptions.series[0].data = timeseries(cdata.fv_perc).map(([k, d]) => [Date.parse(k), d])
    this.chartOptions.title.text = cdata.title
  }
}
</script>
