<template>
  <div>
    <highcharts :options="chartOptions" :callback="addDescriptionButton"></highcharts>
    <v-dialog scrollable max-width="900px" v-model="openDialog">
      <v-card>
        <v-card-title>ROIIC</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px;width:900px">
          <div class="chart-description">
            <p>
              This is the return on the incremental invested capital and is a core component of the model. Given the
              time lag between when capital is invested/deployed and when that capital starts to generate returns, it is
              appropriate to introduce a time lag to the calculation and take averages for the earnings and invested
              capital numbers. For the numerator, the model takes into account the average change in net operating
              profit for the trailing two years and the average incremental invested capital for the trailing four
              years.
            </p>
          </div>
          <v-card-text class="formula">
            <img :src="`${publicPath}files/formulas/roiic_1.PNG`" />
          </v-card-text>
          <div class="chart-description">
            <p>
              The ROIIC metric is only calculated if the invested capital base is growing – that is, when the company is
              not investing capital into the business, not only does an attempt to measure ROIIC on a decreasing
              invested capital base not make intuitive sense, it will give a mathematically misleading answer since it
              can produce a negative number even if returns are increasing.
            </p>
            <p>
              As a result, when observing the ROIIC charts, it is important to remember that should the ROIIC not be
              calculated for a particular time period, it is likely to be due the invested capital base decreasing for
              the company.
            </p>
            <p>
              The ROIIC projected into the future is a fundamental component to the value creating abilities of the
              company and therefore is key to the model. Value is only being created for the shareholders if the company
              is able to generate returns above its cost of capital i.e. ROIIC > cost of capital. Should this occur,
              then the spread of ROIIC above the cost of capital multiplied by the invested capital projected into the
              future generates the excess returns. These excess returns in the future capitalised at the cost of capital
              gives us the nominal future value, either implied in the market or calculated given the user inputs.
            </p>
            <p>
              There is not a single solution between the overall level of returns and the duration of those returns to
              generate the future value. More explicitly, the same future value can be generated between investing at
              higher rates for a shorter duration or investing at lower rates for a longer duration. The model optimises
              between these two factors by aiming to make the excess returns for as short a duration as possible whilst
              ensuring the steepness of the returns projected in the future is in line with the volatility of ROIIC in
              the past. The historical volatility of ROIIC may be misrepresented (e.g. if low levels of invested capital
              for a few years causes a spike higher in ROIIC) so the model allows users to override the returns
              steepness parameter with a higher number pushing out the returns over a longer duration.
            </p>
            <p>
              Following this optimisation process, the ultimate time taken for the returns to fade to the cost of
              capital is deemed to be the competitive advantage period of the company. The user is able to input their
              own value to see what the model deems fair value of the company over different competitive advantage
              periods.
            </p>
            <p>
              The starting point for projecting ROIIC into the future is the current level of ROIIC. The end point of
              ROIIC is the cost of capital. If we are solving for the future value implied in the market, the model
              optimises the ROIIC path in between these two points with the constraint that we need to create the level
              of future value implied. If the user is specifying the level of ROIIC, this is simply fed directly into
              the model.
            </p>
            <p>
              Given the beginning of the ROIIC projection into the future starts with the current level of ROIIC, there
              are two important things to note:
            </p>
            <ol>
              <li>
                If there is no value for the current ROIIC (e.g. decreasing invested capital base, as explained above),
                the current level of ROIC is used instead.
              </li>
              <li>
                Given the potential volatility inherent in the calculation (e.g. low invested capital growth leads to
                small denominator and potentially extremely high ROIIC number), users are also able to override the
                current ROIIC number that the model uses.
              </li>
            </ol>
            <br />
            <p>
              The curved ROIIC and flat ROIIC generate the same amount of future value given the projected level of
              invested capital. Whereas the smoother ROIIC curve starting at the current ROIIC and ending at the cost of
              capital may follow reality more closely, the flat ROIIC gives a good indication of the average levels
              required during the competitive advantage period and it easier to communicate.
            </p>
            <p>
              The ROIIC is ultimately the return on the incremental dollar invested by management. There is certainly a
              risk of false precision over this so it is important to look at general levels of longer periods of time
              rather than focusing on one particular year. Moreover, one must remember that this number measures the
              returns not just for one time period but the return on all future time periods. This is of course as one
              would do with any investment but can easily be overlooked and/or misunderstood within this valuation
              framework.
            </p>
            <p>
              With ROIIC playing such a central role to value creation, how can analysts understand what it could look
              like in the future? There are at least three options:
            </p>
            <ol>
              <li>
                Look at historical levels, understand at what stage in the corporate life cycle the company is and how
                returns will evolve into the future relative to its own history.
              </li>
              <li>
                Look at the ROIIC of peers, both in the same time period and historically. This can be done using the
                comparison charts at the bottom of the page.
              </li>
              <li>
                Fundamental analysis and thinking like a business owner to grasp what the returns on the incremental
                dollar are.
              </li>
            </ol>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="openDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { excessReturnColors } from "@/components/ValuationCharts/ExcessReturnColor.js"
import { timeseries } from "@/utils/timeseries.js"
import numeral from "numeral"
export default {
  props: ["getChartData"],
  methods: {
    addDescriptionButton(chart) {
      var that = this
      chart.renderer
        .button("Description", 0, 0)
        .attr({
          zIndex: 3
        })
        .on("click", function() {
          that.openDialog = true
        })
        .add()
    }
  },
  data: function() {
    return {
      openDialog: false,
      publicPath: process.env.BASE_URL,
      chartOptions: {
        chart: {
          type: "area",
          zoomType: "x"
        },
        colors: excessReturnColors,
        credits: { enabled: false },
        title: {
          text: "ROIIC"
        },
        tooltip: {
          xDateFormat: "%Y-%m-%d",
          formatter: function() {
            // The first returned item is the header, subsequent items are the
            // points
            var dt = new Date(this.x).toDateString()
            return ["<b>" + dt + "</b>"].concat(
              this.points
                ? this.points.map(function(point) {
                    return point.series.name + ": " + numeral(point.y).format("0%")
                  })
                : []
            )
          },
          split: true
        },
        xAxis: {
          type: "datetime"
        },
        yAxis: {
          title: {
            text: ""
          },
          labels: {
            formatter: function() {
              return numeral(this.value).format("0%")
            }
          }
        },
        plotOptions: {
          series: {
            marker: {
              enabled: false
            }
          },
          area: {
            pointStart: 1940,
            marker: {
              enabled: false,
              symbol: "circle",
              radius: 2,
              states: {
                hover: {
                  enabled: true
                }
              }
            }
          }
        },
        series: [
          {
            name: "Historical",
            type: "line",
            data: null
          },
          {
            name: "Projected [Curved ROIIC]",
            type: "line",
            data: null,
            dashStyle: "shortdash"
          },
          {
            name: "Projected [Flat ROIIC]",
            type: "line",
            data: null,
            dashStyle: "shortdash"
          }
        ]
      }
    }
  },
  async mounted() {
    const cdata = await this.getChartData("roiic")

    var limitVal = function(val) {
      //  if (val > 10) return 10
      // if (val < -10) return -10
      return val
    }

    this.chartOptions.series[0].data = timeseries(cdata.historical).map(([k, d]) => [Date.parse(k), limitVal(d)])
    this.chartOptions.series[1].data = timeseries(cdata.curved).map(([k, d]) => [Date.parse(k), limitVal(d)])
    this.chartOptions.series[2].data = timeseries(cdata.flat).map(([k, d]) => [Date.parse(k), limitVal(d)])
  }
}
</script>
